
import { defineComponent, computed, ref, reactive, onMounted, watch } from "vue";
import { setNewPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Pagination from "@/components/Pagination.vue";
import {
  isUndefined,
  useQuery,
  useMutateQuery,
  useHasRole
} from "@/core/helpers/common-helper";
import { convertToFullDateTimeWithoutSecond } from "@/core/helpers/date-helper";
import TableSkeleton from "@/components/skeleton/Table.vue";
import { useI18n } from "vue-i18n";
import usePaymentList from "@/core/services/compositions/bill/usePaymentList";
import FilterPayment from "./filter-payment.vue";
import { numberFormat } from "@/core/helpers/currency-helper";
import { MenuComponent } from "@/assets/ts/components";
import { ElNotification } from 'element-plus'
import { useRouter } from "vue-router";
import moment from 'moment'
import useDownload from "@/core/helpers/download";

export default defineComponent({
  components: {
    Pagination,
    TableSkeleton,
    FilterPayment
  },
  setup() {
    // init
    const { t } = useI18n();
    const router = useRouter();

    // breadcrumb
    setNewPageBreadcrumbs(t("payment.breadcrumb"), [{ title: t("payment.title") }]);

    const paginationRef = ref(null);
    const { query } = useQuery();

    const form = reactive({
      payment_ref_id: '',
      va_number: '',
      bcn: '',
      type: '',
      invoice_number: '',
      channel: '',
      date: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
      order_by: 'created_at',
      order: 'DESC',
      ts: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
      invocation: '',
      bill: '',
    });

    const fetchParams = computed(() => ({
      page: query.value.page || 1,
      limit: query.value.limit || 20,
      payment_ref_id: query.value.payment_ref_id || form.payment_ref_id,
      va_number: query.value.va_number || form.va_number,
      bcn: query.value.bcn || form.bcn,
      type: query.value.type || form.type,
      invoice_number: query.value.invoice_number || form.invoice_number,
      channel: query.value.channel || form.channel,
      date: query.value.date || form.date,
      order_by: 'created_at',
      order: 'DESC',
      ts: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
      invocation: query.value.invocation || form.invocation,
      bill: query.value.bill || form.bill,
      // date: form.date
    }));
    const { data, isValidating, error } = usePaymentList(() => fetchParams.value);

    watch(error, (error) => {
      if (error.response.status == 403) {
        router.push({ name: "403" });

        return;
      }
      ElNotification({
        title: error.response.data.rc,
        message: error.response.data.message,
        type: 'error',
      })
    });

    watch(query, (query) => {
      if (Object.keys(query).length === 0) {
        Object.assign(form, {
          payment_ref_id: '',
          va_number: '',
          bcn: '',
          type: '',
          invoice_number: '',
          channel: '',
          date: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
          order_by: 'created_at',
          order: 'DESC',
          ts: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
          invocation: '',
          bill: '',
        });
      }
    });

    const { mutateQuery } = useMutateQuery();
    const handleChangePage = (nextPage: number) => {
      mutateQuery({
        page: nextPage
      });
    };

    const roleUtils = useHasRole();

    const filtered = (filters) => {
      if (screen.width <= 760) {
        MenuComponent.hideDropdowns(undefined);
      }
      Object.assign(form, filters);
      mutateQuery({
        page: 1,
        payment_ref_id: form.payment_ref_id,
        va_number: form.va_number,
        bcn: form.bcn,
        type: form.type,
        invoice_number: form.invoice_number,
        channel: form.channel,
        date: form.date,
        order_by: 'created_at',
        order: 'DESC',
        invocation: '',
        bill: '',
        ts: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
      });
    };

    const { handleDownloadToFile, isDownloading } = useDownload();

    const download = (file) => {
      handleDownloadToFile(`/app/payment`, file,
        {
          download: true,
          payment_ref_id: query.value.payment_ref_id || form.payment_ref_id,
          va_number: query.value.va_number || form.va_number,
          bcn: query.value.bcn || form.bcn,
          type: query.value.type || form.type,
          invoice_number: query.value.invoice_number || form.invoice_number,
          channel: query.value.channel || form.channel,
          date: query.value.date || form.date,
          order_by: 'created_at',
          order: 'DESC',
        }
      );
    }

    onMounted(() => {
      MenuComponent.reinitialization();
    });

    const gotoBill = (id) => {
      router.push({ name: "app.bill", query: { bill: id, date: ['', ''] } });
    };

    return {
      ...roleUtils,
      data,
      isValidating,
      isUndefined,
      convertToFullDateTimeWithoutSecond,
      handleChangePage,
      paginationRef,
      numberFormat,
      filtered,
      moment,
      gotoBill,
      download,
      isDownloading
    };
  },
  methods: {}
});
