
import { defineComponent, reactive } from "vue";
import moment from 'moment';
import {
  useQuery,
} from "@/core/helpers/common-helper";
import usePaymentType from "@/core/services/compositions/enum/usePaymentType";
import useChannel from "@/core/services/compositions/enum/useChannel";
import { useStore } from "vuex";
import { checkRole } from '@/router/authentication'

interface Filter {
  payment_ref_id: string;
  va_number: string;
  bcn: string;
  type: string;
  invoice_number: string;
  channel: string;
  date: Array<string>;
}

export default defineComponent({
  name: "filter-payment",
  components: {},
  setup(props, context) {
    const { paymentType } = usePaymentType();
    const { channel } = useChannel();
    const { query } = useQuery();

    const data = reactive<Filter>({
      payment_ref_id: query.value.payment_ref_id || '',
      va_number: query.value.va_number || '',
      bcn: query.value.bcn || '',
      type: query.value.type || '',
      invoice_number: query.value.invoice_number || '',
      channel: query.value.channel || '',
      date: query.value.date || [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
    });

    const filter = () => {
      context.emit('filtered', data);
    };

    const reset = () => {
      data.payment_ref_id = '';
      data.va_number = '';
      data.bcn = '';
      data.type = '';
      data.invoice_number = '';
      data.channel = '';
      data.date = [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')];
    };

    return {
      data,
      filter,
      reset,
      paymentType,
      channel,
      checkRole
    };
  },
  computed: {
    userData() {
      return useStore().getters.currentUser;
    }
  }
});
